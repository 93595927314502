import { Directive, HostListener } from '@angular/core';
import {Location} from '@angular/common';

@Directive({
  selector: '[backButton]'
})
export class BackButtonDirective {

  constructor(
    private location: Location
  ) { 

  }

  @HostListener('click', ['$event.target'])
  onClick(btn: any): void {
    // console.log(btn);
    this.location.back();
 }

}
