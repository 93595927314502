import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicComponentDirective } from './dynamic-component.directive';
import { BackButtonDirective } from './back-button.directive';

@NgModule({
  declarations: [DynamicComponentDirective, BackButtonDirective],
  imports: [
    CommonModule
  ],
  exports: [DynamicComponentDirective, BackButtonDirective]
})
export class DirectivesModule { }
